<template>
  <section class="page_cell">
    <el-card>
      <m-search
        :searchItemList="searchItemList"
        @on-search-submit="onSearchSubmit"
        @search="onSearchSubmit"
      ></m-search>
      <div class="flex-center radio-cell" style="justify-content: flex-start">
        <el-button
          class="mlr-sm"
          type="primary"
          style="margin-top: 10px; margin-bottom: 10px; margin-left: 10px"
          @click="$router.push('./edit')"
          >新增市场</el-button
        >
      </div>
      <el-table
        :data="tableList"
        stripe
        border
        :header-cell-style="{
          'font-size': '14px',
          color: '#909399',
          'font-weight': '400',
          background: '#F7F8FA',
        }"
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column align="center" label="市场名称">
          <template slot-scope="scope">
            <div class="flex_center">
              {{ scope.row.title }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="市场封面">
          <template slot-scope="scope">
            <div class="flex_center">
              <img :src="scope.row.coverImage" class="market_cover"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <div class="flex_center">
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="changeStatus($event, scope.row)"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button type="text" @click="prevShowEditModal(scope.row)">编辑</el-button>
              <el-button
                type="text"
                @click="deleteItem(scope.row)"
                style="color: #f0232d"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="tableList.length > 0" class="flex_end p-sm">
        <el-pagination
          @current-change="getList"
          @size-change="changePageGetList"
          :current-page.sync="search.page"
          :page-size="search.pageSize"
          layout="total,sizes, prev, pager, next, jumper"
          :total="count">
        </el-pagination>
      </div>
    </el-card>
    <!-- 详情抽屉 -->
    <el-drawer
      :visible.sync="drawer"
      :append-to-body="true"
      v-if="drawer"
      :with-header="false"
      size="50%">
      <list-detail/>
    </el-drawer>
  </section>
</template>


<script>
import Market from '@/axios/api/market.js'

export default {
  data() {
    return {
      search: {
        page: 1,
        pageSize: 10,
        title: ''
      },
      sort: 1,
      tableList: [],
      loading: false,
      drawer: false, // 抽屉是否显示
      count: 0,
      downCount: 0,
      upCount: 0,
      classifyList: []
    }
  },
  computed: {
    searchItemList() {
      return [
        {
          label: "市场名称",
          type: "input",
          param: "title",
        }
      ];
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList(i) {
      if (i) {
        this.search.page = i;
      }
      this.loading = true
      let res = await Market.getMarketList(this.search)
      this.loading = false
      if (res.status == 1) {
        this.tableList = res.data || []
        this.count = res.extra.count
        this.downCount = res.extra.countOFF
        this.upCount = res.extra.countOn
      }
    },
    // 页大小改变请求列表数据
    changePageGetList(size){
      this.search.pageSize = size
      this.getList(1)
    },
    // 点击筛查、重置按钮
    onSearchSubmit(item) {
      console.log(item);
      this.search = {
        ...this.search,
        ...item,
      };
      this.getList(1);
    },
     // 打开分类抽屉
     openClassfyDrawer() {
      this.drawer = true
    },
    // 打开详情抽屉
    openDetailDrawer(row) {
      this.$router.push({ path: "./edit", query: { id: row.id } })
    },
    // 打开编辑
    prevShowEditModal(row) {
      this.$router.push({path: './edit', query: {
        id: row.id
      }})
    },
    // 删除列表项
    deleteItem(row) {
      let that = this
      this.$Modal.confirm({
        title: '提示',
        content: '是否删除，请确认',
        onOk: () => {
          Market.deleteMarket(row.id).then((res) => {
            if (res.status == 1) {
              this.$message.success("修改成功！");
              this.getList();
            }
          });
        }
      });
    },
    // 商品上架或下架
    changeStatus(e, row) {
      let oldStatus = row.status == 0 ? 1 : 0
      console.log(oldStatus);
      let that = this;
      row.status = oldStatus
      that.$Modal.confirm({
        title: "提示",
        content: `是否${oldStatus == 0 ? '上' : '下'}架，请确认`,
        onOk: () => {
          Market.editStatus({id: row.id, status: oldStatus}).then((res) => {
            if (res.status == 1) {
              row.status = oldStatus;
              this.getList();
              this.$message.success("修改成功！");
            }
          });

        },
      });
    },
    getRealValue(obj, property) {
      let value = obj;
      let propertyList = property.split(".");
      propertyList.forEach((name) => {
        if (value) {
          value = value[name];
        }
      });
      if (typeof value === "boolean") {
        return value ? "是" : "否";
      } else {
        return value;
      }
    },
  }
}
</script>
<style lang="less" scoped>
.flex_end {
 display: flex;
 justify-content: flex-end;
}
</style>
<style lang="less">
.page_cell {
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
}
.radio-cell .el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background: #E6F1FC;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #A3D0FD;
  color: #1989FA;
}

.market_cover {
  max-width: 113px;
  max-height: 54px;
}
</style>
